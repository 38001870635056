import Cookies from 'vue-cookies';

const getFetchInfo = () => {
  /** e.g. ghttps://stage.sips.aucmint.com/api/v1/ */
  const baseURL = process.env.VUE_APP_API_URL;
  const Authorization = Cookies.get('jwt');
  const headers = {
    Authorization,
    'Content-Type': 'application/json',
  };
  return { baseURL, headers };
};

export const fetcher = async (url, data) => {
  const { baseURL, headers } = getFetchInfo();
  const res = await fetch(`${baseURL}${url}`, { headers, body: JSON.stringify(data), method: 'POST' });
  return res.json();
};
