<template>
  <v-dialog v-model="parent.uploadShipmentDocumentsModal" max-width="800px" persistent scrollable>
    <v-card height="fit-content">
      <v-card-title class="text-h6 white--text px-2 py-1" :class="titleClass">
        出荷書類差し替え{{ `｜` + parent?.uploadingRow?.member_name_ja }}
      </v-card-title>
      <p class="red--text mx-4 mb-0 text-caption">＊エクセルのみアップロード可能</p>
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">Invoice</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div v-if="shipmentDocuments.invoiceExcelFile && !shipmentDocuments.invoiceExcelFile.name" class="pl-4">
                <a style="line-height: 35px" @click="downloadFile({ key: shipmentDocuments.invoiceExcelFile })">
                  {{ shipmentDocuments.invoiceExcelFile.replace(/^.*[\\\/]/, '') }}
                </a>
                <v-btn
                  dense
                  color="red accent-4 white--text"
                  :loading="uploadLoading"
                  @click="shipmentDocuments.invoiceExcelFile = null"
                  style="float: right"
                >
                  削除
                </v-btn>
              </div>
              <div v-else>
                <v-file-input
                  class="px-4 pt-4 py-0"
                  v-model="shipmentDocuments.invoiceExcelFile"
                  @change="handleChangeInvoiceFile"
                  :loading="shippingDocs === null"
                  :disabled="shippingDocs === null"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  counter
                >
                </v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">Details</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div
                v-if="
                  shipmentDocuments &&
                  shipmentDocuments.invoiceDetailsExcelFile &&
                  !shipmentDocuments.invoiceDetailsExcelFile.name
                "
                class="pl-4"
              >
                <a style="line-height: 35px" @click="downloadFile({ key: shipmentDocuments.invoiceDetailsExcelFile })">
                  {{ shipmentDocuments.invoiceDetailsExcelFile.replace(/^.*[\\\/]/, '') }}
                </a>
                <v-btn
                  dense
                  color="red accent-4 white--text"
                  :loading="uploadLoading"
                  @click="shipmentDocuments.invoiceDetailsExcelFile = null"
                  style="float: right"
                >
                  削除
                </v-btn>
              </div>
              <div v-else>
                <v-file-input
                  class="px-4 pt-4 py-0"
                  v-model="shipmentDocuments.invoiceDetailsExcelFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  counter
                >
                </v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">梱包明細</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div
                v-if="shipmentDocuments.packingDetailsExcelFile && !shipmentDocuments.packingDetailsExcelFile.name"
                class="pl-4"
              >
                <a style="line-height: 35px" @click="downloadFile({ key: shipmentDocuments.packingDetailsExcelFile })">
                  {{ shipmentDocuments.packingDetailsExcelFile.replace(/^.*[\\\/]/, '') }}
                </a>
                <v-btn
                  dense
                  color="red accent-4 white--text"
                  :loading="uploadLoading"
                  @click="shipmentDocuments.packingDetailsExcelFile = null"
                  style="float: right"
                >
                  削除
                </v-btn>
              </div>
              <div v-else>
                <v-file-input
                  class="px-4 pt-4 py-0"
                  v-model="shipmentDocuments.packingDetailsExcelFile"
                  @change="handleChangePackingListFile"
                  :loading="shippingDocs === null"
                  :disabled="shippingDocs === null"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  counter
                >
                </v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">出荷明細</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div
                v-if="
                  shipmentDocuments &&
                  shipmentDocuments.shippingDetailsExcelFile &&
                  !shipmentDocuments.shippingDetailsExcelFile.name
                "
                class="pl-4"
              >
                <a style="line-height: 35px" @click="downloadFile({ key: shipmentDocuments.shippingDetailsExcelFile })">
                  {{ shipmentDocuments.shippingDetailsExcelFile.replace(/^.*[\\\/]/, '') }}
                </a>
                <v-btn
                  dense
                  color="red accent-4 white--text"
                  :loading="uploadLoading"
                  @click="shipmentDocuments.shippingDetailsExcelFile = null"
                  style="float: right"
                >
                  削除
                </v-btn>
              </div>
              <div v-else>
                <v-file-input
                  class="px-4 pt-4 py-0"
                  v-model="shipmentDocuments.shippingDetailsExcelFile"
                  @change="handleChangeShippingDetailsFile"
                  :loading="shippingDocs === null"
                  :disabled="shippingDocs === null"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  counter
                >
                </v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">出荷チェックシート</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div
                v-if="shipmentDocuments?.shippingCheckExcelFile && !shipmentDocuments?.shippingCheckExcelFile?.name"
                class="pl-4"
              >
                <a
                  style="line-height: 35px"
                  @click="
                    downloadFile({
                      key: shipmentDocuments.shippingCheckExcelFile,
                    })
                  "
                >
                  {{ shipmentDocuments.shippingCheckExcelFile.replace(/^.*[\\\/]/, '') }}
                </a>
                <v-btn
                  dense
                  color="red accent-4 white--text"
                  :loading="uploadLoading"
                  @click="shipmentDocuments.shippingCheckExcelFile = null"
                  style="float: right"
                >
                  削除
                </v-btn>
              </div>
              <div v-else>
                <v-file-input
                  class="px-4 pt-4 py-0"
                  v-model="shipmentDocuments.shippingCheckExcelFile"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  counter
                >
                </v-file-input>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <div class="d-flex justify-center pa-2">
        <v-progress-circular v-if="watchRequired === null" indeterminate color="primary"></v-progress-circular>
      </div>

      <v-card v-if="watchRequired" class="mx-4 my-1 pt-2" outlined elevation="0">
        <v-row no-gutters>
          <v-col class="d-flex pb-0" cols="12" sm="2">
            <v-card-text class="text-start text-sm-end align-self-center">Watch worksheet</v-card-text>
          </v-col>
          <v-col class="d-flex pb-0" cols="12" sm="10">
            <div class="align-self-center mr-4" style="width: 636px">
              <div class="px-4">
                <v-file-input
                  class="pt-4 py-0"
                  :value="watchValidFiles"
                  @change="changeWatchWorksheets"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  outlined
                  clearable
                  multiple
                >
                </v-file-input>
                <p class="pl-8 grey--text text--darken-1">
                  ファイル名に「Watch_Worksheet_(Watch機種名)_(単価)」の文を含むファイルのみアップロード可能。
                  Watch機種名と単価の記載内容から、差替え対象のファイルを判別します。
                </p>
              </div>
              <v-dialog v-model="watch.dialog" max-width="800">
                <v-card>
                  <v-card-title class="text-h5">Watch worksheet</v-card-title>
                  <div v-if="watchValidFiles.length !== 0" class="ml-6 pb-10">
                    <p>以下のファイルはアップロード予定です。</p>
                    <v-list class="mx-4">
                      <v-list-item v-for="file in watchValidFiles" :key="file.name">
                        {{ file.name }}
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-if="watchInvalidFiles.length !== 0" class="ml-6">
                    <p class="red--text">
                      <v-icon color="red darken-1" v-bind="attrs" v-on="on"> mdi-alert-circle </v-icon>
                      {{
                        watchValidFiles.length !== 0
                          ? '以下のファイルはアップロードされません。'
                          : 'すべてのファイルはアップロードされません。'
                      }}
                    </p>
                    <v-list class="mx-4">
                      <v-list-item v-for="file in watchInvalidFiles" :key="file.name" class="red--text">
                        {{ file.name }}
                      </v-list-item>
                    </v-list>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="watch.dialog = false"> OK </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
        <div class="pa-4 pl-10">
          <div
            v-for="key in uploadWatchWorksheetKeys"
            class="my-2 d-flex justify-space-between align-center"
            :key="key"
          >
            <a style="line-height: 35px" @click="downloadFile({ key })">
              {{ key.replace(/^.*[\\\/]/, '') }}
            </a>
            <v-btn
              dense
              color="red accent-4 white--text"
              :loading="uploadLoading"
              style="float: right"
              @click="deleteShipmentDocuments(key)"
            >
              削除
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="selectedFileError.dialog" max-width="800" persistent>
        <v-card>
          <v-card-title class="text-h5 red--text">{{ selectedFileError.result?.title }}</v-card-title>
          <p class="ml-6 red--text">{{ selectedFileError.result?.subtitle }}</p>
          <div v-for="{ message, diff } in selectedFileError.result?.errors ?? []" :key="message" class="ml-6 pb-10">
            <p class="ml-1">{{ message }}</p>
            <v-simple-table v-if="diff" class="ml-4 mr-10 grey lighten-4">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-right" style="width: 300px">{{ `該当案件の ${diff.label}` }}</td>
                    <td>{{ diff.doc }}</td>
                  </tr>
                  <tr>
                    <td class="text-right">{{ `選択されたファイルの ${diff.label}` }}</td>
                    <td class="red--text">{{ diff.file }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeSelectedFileErrorDialog"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-actions class="d-flex justify-end">
        <v-btn dense color="primary darken-1" :loading="uploadLoading" @click="register"> OK </v-btn>
        <v-btn
          dense
          color="orange darken-1"
          text
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="parent.uploadShipmentDocumentsModal = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { fetcher } from '../../funcs/fetcher';
import { fileToRows } from '../../funcs/file';
import { invoiceValidator, packingListValidator, shippingDetailsValidator } from '../../funcs/validate-files';

/** @typedef {import('../../../../infrastructure/common/shukka-system/gateway-resource/common/types').ShippingDocument} ShippingDocument */
/** @typedef {import('../../funcs/types').ValidationError} ValidationError */

/** @type {(file: File, doc: ShippingDocument) => boolean} */
const isMatchFile = (file, doc) => {
  if (doc.template !== 'watch_worksheet.xlsx') return false;
  return file.name.includes(doc.fileKey);
};

export default {
  props: {
    parent: {
      type: Object,
      default: Object,
      required: true,
    },
  },
  data() {
    return {
      /** @type {ShippingDocument[] | null} */
      shippingDocs: null,
      /** @type {Record<string, File | null>} */
      shipmentDocuments: {
        invoiceExcelFile: null,
        invoiceDetailsExcelFile: null,
        packingDetailsExcelFile: null,
        shippingDetailsExcelFile: null,
        shippingCheckExcelFile: null,
      },

      selectedFileError: {
        dialog: false,
        /** @type {ValidationError | null} */
        result: null,
      },

      // Watch Worksheet
      watch: {
        dialog: false,
        /** @type {File[]} */
        files: [],
        /**
         * ## lockedフラグについて
         *
         * ファイルを選択すると、On Changeイベントが2回以上発火します。
         *
         * ### 初回の発火
         * 選択されたファイルがchangeWatchWorksheetsメソッドに渡されます。
         *
         * ### 2回目以降の発火
         * フォームにはwatchValidFilesをバインドしています。
         * 初回の発火によってwatchValidFilesが変更されため2回目が発火します。
         * 発火によりwatchValidFilesがchangeWatchWorksheetsメソッドに渡されます。
         *
         * つまり2回目以降は invalidなファイルが渡されることがなくなっていまい、
         * 2回目以降で初回と同じように渡されたfilesをthis.watch.filesにセットしてしまうと、
         * アップロードされる予定のないファイルについて画面に表示することができなくなってしまいます。
         *
         * つまり2回目以降の発火では処理しないためのフラグとしてlockedフラグを導入しました。
         * 1回ファイルを選択するとchangeWatchWorksheetsメソッドは3回呼ばれます。
         * その処理が0.2秒以上かかることはないためlockedフラグは0.2秒で解除されるように設定しています。
         */
        locked: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      error: 'ui/error',
      success: 'ui/success',
      packingsReadyForShipment: 'shukkaShoruiSakusei/packingsReadyForShipment',
      uploadLoading: 'ui/uploadLoading',
      user: 'defined/user',
    }),
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
    watchRequired() {
      if (this.shippingDocs === null) return false;
      return this.shippingDocs.some((doc) => doc.template === 'watch_worksheet.xlsx');
    },
    watchValidFiles() {
      if (this.shippingDocs === null) return [];
      return this.watch.files?.filter((file) => this.shippingDocs.some((doc) => isMatchFile(file, doc))) ?? [];
    },
    watchInvalidFiles() {
      if (this.shippingDocs === null) return [];
      return this.watch.files?.filter((file) => this.shippingDocs.every((doc) => !isMatchFile(file, doc))) ?? [];
    },
    uploadWatchWorksheetKeys() {
      if (this.shippingDocs === null) return [];
      return this.shippingDocs
        .filter((doc) => doc.template === 'watch_worksheet.xlsx' && doc.uploadedFile !== null)
        .map((doc) => doc.uploadedFile);
    },
  },
  methods: {
    ...mapActions({
      getAwsCredentials: 'api/getAwsCredentials',
      upload: 'common/upload',
      apiUpdatePacking: 'api/updateEachPackingInfo',
      downloadFile: 'api/downloadFile',
    }),
    async register() {
      const credentials = await this.getAwsCredentials({
        type: 'upload-document',
      });
      await Promise.all(
        [
          this.shipmentDocuments.invoiceExcelFile,
          this.shipmentDocuments.invoiceDetailsExcelFile,
          this.shipmentDocuments.packingDetailsExcelFile,
          this.shipmentDocuments.shippingDetailsExcelFile,
          this.shipmentDocuments.shippingCheckExcelFile,
        ]
          .map(async (row) => {
            if (row && row.name && row.size) {
              await this.upload({
                s3Info: credentials,
                file: row,
                keyParams: [this.parent.uploadingRow.shipping_seq_number],
              }).then((result) => {
                row.key = result.Key;
                return Promise.resolve(row);
              });
            } else {
              return Promise.resolve(null);
            }
          })
          .concat([this.uploadShipmentDocuments()]),
      );
      Object.keys(this.shipmentDocuments).forEach((key) => {
        this.shipmentDocuments[key] =
          (this.shipmentDocuments[key] ? this.shipmentDocuments[key].key : null) || this.shipmentDocuments[key];
      });
      const updates = [
        this.parent.uploadingRow.shipping_seq_number,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.shipmentDocuments,
        null,
        null,
        this.user ? this.user.userName : 'SYSTEM',
        null,
        null,
        null,
        null,
      ];
      await this.apiUpdatePacking(updates);
      this.parent.uploadShipmentDocumentsModal = false;
      const item = this.packingsReadyForShipment.find(
        (s) => s.shipping_seq_number === this.parent.uploadingRow.shipping_seq_number,
      );
      item.contents_update_datetime = moment().format('YYYY/MM/DD HH:mm');
    },
    /** @type {(file: File) => void} */
    async handleChangeInvoiceFile(file) {
      if (process.env.VUE_APP_UPLOAD_FILE_VALIDATION_FEATURE !== 'true') return;
      if (file === null) {
        this.shipmentDocuments.invoiceExcelFile = null;
        return;
      }
      const rows = await fileToRows(file, 0);
      const invoiceDoc = this.shippingDocs.find((doc) => doc.fileKey === 'invoice');
      if (invoiceDoc === undefined) throw new Error('Invoice doc not found.');

      const result = invoiceValidator(rows, invoiceDoc);
      if (result) {
        this.selectedFileError.dialog = true;
        this.selectedFileError.result = result;
        this.shipmentDocuments.invoiceExcelFile = null;
      }
    },
    /** @type {(file: File) => void} */
    async handleChangePackingListFile(file) {
      if (process.env.VUE_APP_UPLOAD_FILE_VALIDATION_FEATURE !== 'true') return;
      if (file === null) {
        this.shipmentDocuments.packingDetailsExcelFile = null;
        return;
      }
      const rows = await fileToRows(file, 0);
      const invoiceDoc = this.shippingDocs.find((doc) => doc.fileKey === 'invoice');
      if (invoiceDoc === undefined) throw new Error('Invoice doc not found.');

      const result = packingListValidator(rows, invoiceDoc);
      if (result) {
        this.selectedFileError.dialog = true;
        this.selectedFileError.result = result;
        this.shipmentDocuments.packingDetailsExcelFile = null;
      }
    },
    /** @type {(file: File) => void} */
    async handleChangeShippingDetailsFile(file) {
      if (process.env.VUE_APP_UPLOAD_FILE_VALIDATION_FEATURE !== 'true') return;
      if (file === null) {
        this.shipmentDocuments.shippingDetailsExcelFile = null;
        return;
      }
      const rows = await fileToRows(file, 0);
      const invoiceDoc = this.shippingDocs.find((doc) => doc.fileKey === 'invoice');
      if (invoiceDoc === undefined) throw new Error('Invoice doc not found.');

      const result = shippingDetailsValidator(rows, invoiceDoc);
      if (result) {
        this.selectedFileError.dialog = true;
        this.selectedFileError.result = result;
        this.shipmentDocuments.shippingDetailsExcelFile = null;
      }
    },
    closeSelectedFileErrorDialog() {
      this.selectedFileError.dialog = false;
      this.selectedFileError.result = null;
    },
    lockWatch() {
      this.watch.locked = true;
      window.setTimeout(() => {
        this.watch.locked = false;
      }, 200);
    },
    /** @type {(files: File[]) => void} */
    changeWatchWorksheets(files) {
      if (this.watch.locked) return;
      this.watch.dialog = files.length !== 0;
      this.watch.files.splice(0, this.watch.files.length);
      this.watch.files.push(...files);
      this.lockWatch();
    },
    async fetchShippingDocs() {
      this.shippingDocs = null;
      /** @type {any[]} */
      const res = await fetcher('download-merged-excels', {
        format: 'json',
        shippingSeqNumbers: [this.parent.uploadingRow.shipping_seq_number],
      });
      this.shippingDocs = res[0];
    },
    async uploadShipmentDocuments() {
      const merged = this.watchValidFiles.map((file) => {
        const doc = this.shippingDocs.find((d) => isMatchFile(file, d));
        if (!doc) throw new Error('doc not found');
        return { ...doc, file };
      });
      const documentEntries = merged.map((doc) => [doc.fileKey, doc.file.name]);
      const res = await fetcher('get-docs-upload-urls', {
        shipping_seq_number: this.parent.uploadingRow.shipping_seq_number,
        documents: Object.fromEntries(documentEntries),
      });
      await Promise.all(
        merged.map(async (doc) => {
          const url = res[doc.fileKey];
          await fetch(url, { method: 'PUT', body: doc.file });
        }),
      );
      this.watch.files = [];
    },
    /** @type {(key: string) => Promise<void>} */
    async deleteShipmentDocuments(key) {
      const index = this.shippingDocs.findIndex((doc) => doc.uploadedFile === key);
      if (index === -1) throw new Error('doc not found');
      this.shippingDocs[index].loading = true;
      const prefix = `${key.split('/').slice(0, 3).join('/')}/`;
      await fetcher('delete-files', { prefixes: [prefix] });
      this.shippingDocs[index].loading = false;
      this.shippingDocs[index].uploadedFile = null;
    },
  },
  watch: {
    'parent.uploadShipmentDocumentsModal'() {
      this.shipmentDocuments = this.parent.uploadingRow.shipment_documents ?? {};
      this.fetchShippingDocs();
      this.watch.files = [];
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
