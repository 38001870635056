// @ts-check
import * as XLSX from 'xlsx';

/** @typedef {import('xlsx').Sheet} Sheet */

/** @type {(file: File, index: number) => Promise<Sheet>} */
export const fileToSheet = async (file, index) => {
  const buffer = await file.arrayBuffer();
  const wb = XLSX.read(buffer, { cellFormula: true });
  const sheetName = wb.SheetNames[index];
  return wb.Sheets[sheetName];
};

/** @type {(file: File, index: number) => Promise<(string|number)[][]>} */
export const fileToRows = async (file, index) => {
  const sheet = await fileToSheet(file, index);
  return XLSX.utils.sheet_to_json(sheet, { header: 1 });
};
